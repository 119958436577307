// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// Polyfills
import 'core-js/actual/promise';
import 'core-js/actual/string/includes';
import 'core-js/actual/array/includes';

// Application start

import './startup/libraries'
import './startup/vue-addons'
import './startup/vue-filters'
import './startup/global-components'
import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import config from './startup/config'

// If website visitor not using one of the two domains, redirect to guest domain. http -> https handled by webserver
if (!config.usingLoggedInDomain && !config.usingGuestDomain) {
	let redirect = `https://${config.guestDomain}`;
	if (location.port) {
		redirect = redirect + `:${location.port}`;
	}
	// location.href = redirect;
}
Vue.config.productionTip = false;


let startup = function () {
	new Vue({
		el:         '#app',
		router,
		store,
		template:   '<App/>',
		components: {
			App
		}
	});
};

startup();
