<template>
	<div class="container my-100 football">
		<div class="theme-box py-4 px-3 px-xl-5 text-center">
			<h1>You are playing for $10,000</h1>
			<p class="h3 color-primary font-weight-normal">Pick 3 Numbers Below</p>

			<div class="d-flex flex-wrap justify-content-center">
				<div v-for="i in 25" :key="i">
					<label :for="'option' . i">
						<input type="checkbox" :id="'option' . i" name="picknumber" :value="i">
						<div class="football d-flex align-items-center justify-content-center">{{i}}</div>
					</label>
				</div>
			</div>

			<button type="submit" class="btn btn-link">Submit Picks</button>

		</div>
	</div>
</template>

<script>
	export default {
		name: "football-1",
		data() {
			return {};
		},
	};
</script>

<style lang="scss" scoped>
	[type="checkbox"] {
		position: absolute;
		opacity: 0;
		width: 0;
		height: 0;
	}
	[type="checkbox"] + .football {
		width: 118px;
		height: 108px;
		cursor: pointer;
		margin: 5px;
		font-size: 35px;
		font-family: $fontSpecial;
	}
	[type="checkbox"]:checked + .football {
		// on theme scss file
	}
</style>
