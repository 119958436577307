<template>
<!-- They had two designs for winning / losing. These, and the ones of football-2. I guess pick? -->
	<div class="container football">

		<div class="theme-box py-4 px-3 px-xl-5 text-center mx-auto my-100">
			<h1>Winner!</h1>
			<p class="h3 color-primary font-weight-normal">you Picked the WInning Numbers</p>
			<h2 class="h5 mt-5">Claim Your Prize</h2>
			<p>Tellus mauris a diam maecenas sed enim. Aliquet sagittis id consectetur purus ut faucibus pulvinar elementum. Lorem ipsum dolor sit amet consectetur adipiscing elit ut. Quisque id diam vel quam.</p>
			<a href="" class="btn btn-primary btn-block">Enter Another Code</a>
			<a href="" class="btn btn-primary btn-block">View Code History</a>
		</div>

		<div class="theme-box py-4 px-3 px-xl-5 text-center mx-auto my-100">
			<h1>Thank you for playing...</h1>
			<p class="h4 color-primary font-weight-normal">You didn't have the winning numbers</p>
			<p class="h2 my-5 font-weight-normal">You've been entered into the weekly $250 gift card sweepstakes.</p>
			<a href="" class="btn btn-primary btn-block">Enter Another Code</a>
			<a href="" class="btn btn-primary btn-block">View Code History</a>
		</div>

	</div>
</template>

<script>
	export default {
		name: "football-3",
		data() {
			return {};
		},
	};
</script>

<style lang="scss" scoped>
.theme-box {
	max-width: 742px;
}
</style>
