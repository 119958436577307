<template>
	<div class="main main-play">

		<div class="container">
			<lang id="choose-store-title">
				<div class="page-header ticket">
					<h1 class="page-title text-center text-uppercase font-size-h3">Choose your Store</h1>
				</div>
			</lang>

			<ul class="store-list bg-white list-unstyled m-0 p-0 d-flex flex-wrap justify-content-center">
                <li class="list-item" v-for="({showChooseStore, spriteIndex}, bannerTitle ) in $config.banners" v-bind:key="spriteIndex" v-if="showChooseStore">
					<button type="button" class="store square" :class="`store-` + spriteIndex" :title="bannerTitle" @click="openModal($event, bannerTitle, spriteIndex)" v-ada></button>
				</li>
			</ul>
		</div>

		<!-- todo make this work again -->
		<offline-message/>

		<third-party-cookies ref="cookiemonster"></third-party-cookies>

	</div>
</template>

<script>

	import cookie from "../utils/cookie";
	// import Recaptcha from '@/components/Recaptcha';
	import thirdPartyCookies from "../components/thirdPartyCookies";
	import tracker from "../components/trackers/General";

	export default {
		name:       "chooseStore",
		components: {
			// Recaptcha,
			thirdPartyCookies,
			tracker
		},
		created() {
			// Get PHPSESSID for J4U data sharing service
			// cookie.get("PHPSESSID").then(PHPSESSID => {
			// 	this.PHPSESSID = PHPSESSID;
			// });

			this.$EventBus.$on("postMessage", this.messageHandler);
		},
		mounted() {
			let banner = this.$route.params.banner;
			if (banner) {
				this.chooseStore(banner);
			}
		},

		computed: {},

		data() {
			return {
				banner:               false,
				error:                false,
				message:              false,
				// PHPSESSID:            "",
				loginOrRegisterModal: null
			};
		},

		beforeDestroy() {
			this.watchConnectivity(false);
		},

		destroyed() {
			$("body").removeClass("j4u-iframe");
			this.$EventBus.$off("postMessage", this.messageHandler);
		},

		methods: {
			openModal(e, store, index) {
				this.loginOrRegisterModal = this.$modalService
					.create('accountCheck', {
						banner:      store,
						bannerIndex: index
					})
					.setReturnElement(e.target)
					.on('register login', (event, banner) => {
						let url = '/user/start-login';
						if (event.type === 'register') {
							url = '/user/start-register';
						}
						this.$http.post(url, {banner}).then(this.navigateToUrl, this.serverError);
					})
			},

			chooseStore(banner) {
				banner = banner.toLowerCase();
				this.banner = banner;
				this.$http.post("/user/start-login", {banner}).then(this.navigateToUrl);
			},

			serverError(response) {
				this.loginOrRegisterModal.destroy();
				this.loginOrRegisterModal = null;
				this.$modalService.create('errorLoggingIn', {message: `Your request could not be processed at this time. Please try again in a few minutes.`});
			},

			navigateToUrl(response) {
				let uri = null;
				if (response && response.data && response.data.register_url) {
					uri = response.data.register_url
				}


				if (!uri) {
					// critical error
					this.loginOrRegisterModal.destroy();
					this.loginOrRegisterModal = null;
					this.$modalService.create('errorLoggingIn', {message: `Your request could not be processed at this time. Please try again in a few minutes.`});
					return;
				}
				if (this.$config.appVersion) {
					this.$EventBus.$when("deviceready", () => {
						let ref = cordova.InAppBrowser.open(
							uri,
							"_blank",
							"location=no,hardwareback=no,zoom=no"
						);
						this.loginOrRegisterModal.destroy();
						this.loginOrRegisterModal = null;

						let pleaseWaitModal = this.$modalService.create('pleaseWait'),
						    returnedToSPW   = false;

						ref.addEventListener('exit', function () {
							if (!returnedToSPW) {
								pleaseWaitModal.destroy();
							}
						});

						// Every time a new page loads, I want to know about it
						let handler = (e) => {
							if (returnedToSPW) {
								return;
							}

							let domain = e.url.match(/^https?:\/\/([^\/?]+)(?:.*)?$/);
							if (domain) {
								domain = domain[1];
							} else {
								domain = '';
							}

							if (/ripitandwinit(?:codes)?\.com$|^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::8081)?$/.test(domain)) {
								returnedToSPW = true;
								ref.close();

								this.$store.dispatch('auth/refreshUser').then(() => {
									pleaseWaitModal.destroy();

									if (this.$store.get('auth/isLoggedIn')) {
										this.$router.replace({name: 'enter-code'});
									} else {
										this.$modalService.create('errorLoggingIn');
									}
								});
							}
						};

						ref.addEventListener("loadstart", handler);
						ref.addEventListener("loadstop", handler);
					});
				} else {
					// top.addEventListener('beforeunload', () => {
					this.loginOrRegisterModal.destroy();
					this.loginOrRegisterModal = null;
					// });
					top.location.href = uri;
				}
			},

			messageHandler(event) {
				if (event.data.type === "play") {
					if (this.$config.appVersion) {
						location.reload(true);
					} else {
						location.href = "/"; // cause a page refresh on the homepage
					}
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.store-list {
		border: 0 solid transparentize($light, .3);
		border-top-width: 1px;
		border-left-width: 1px;

		.list-item {
			width: 33.333%;
			@media (min-width: $md) {
				width: 20%;
			}
			@media (min-width: $xl) {
				width: 16.666667%;
			}
		}

		.store {
			display: block;
			background-color: transparent;
			background-size: cover;
			transition: transform 250ms;
			width: 100%;
			border: 0;
			padding: 0;

			&:focus,
			&:hover {
				transform: scale(1.1, 1.1);
			}
		}

		$stores: 18;
		@for $i from 1 through $stores {
			.store-#{$i} {
				background-position-y: (100 / ($stores - 1)) * $i + 0%;
			}
		}
	}
</style>
