import {EventBus} from './EventBus'
import store from 'store2';

/**
 * Apps will use nativestorage, everything else use localStorage
 * @type {{getItem(*=): Promise<unknown>, setItem(*=, *=, *): Promise<unknown>}}
 */
const myNativeStorage = {
	getItem(name) {
		return new Promise((resolve) => {

				resolve(store.get(name));


		});
	},

	setItem(name, value, opts) {
		return new Promise((resolve, reject) => {

				resolve(store.set(name, value));


		});
	},

	removeItem(name, value, opts) {
		return new Promise((resolve, reject) => {

				resolve(store.remove(name));


		});
	}
};

export default myNativeStorage;
