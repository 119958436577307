<template>
    <div class="container container-wide h-100">
        <div class="row">
            <div class="col-sm-6 mx-auto">
                <img src="../assets/images/logo-header.png" class="img-fluid" alt="Reasor's Monopoly Pick 3 and Win">
                <router-link :to="{name:'new-player'}" class="btn btn-primary btn-block my-3">Sign Up To Play</router-link>
                <router-link :to="{ name: 'returning-player' }" class="btn btn-primary btn-block my-3">Returning Player</router-link>
            </div>
        </div>
        <div class="position-absolute mx-n1">
            <tracker v-if="$config.useTrackers" name="home"></tracker>
        </div>
    </div>
</template>

<script>
import tracker from "@/components/trackers/General";

export default {
    name:       "home",
    components: {tracker},
    data() {
        return {
            watchConnectivityState: false,
            classname:              "I am a name to get classified",
        };
    },
    computed: {


        isApp() {
            return null;
        },


    },
    methods:  {

    }
};
</script>

<style lang="scss" scoped>

</style>
