<template>
	<div class="container-fluid px-0 pb-100 mt-4 bg-img-dark text-white">
        <h1 class="text-uppercase text-center py-5 my-2">Frequently Asked Questions</h1>
        <div class="container">
            <div class="d-flex flex-column p-4 p-xl-5 bg-white rounded text-dark box-shadow-0-0-10">
				<lang id="faq-top">
					<p class="mb-3">
						Here you will find frequently asked questions from users. Before contacting us, check to see if your question can be quickly answered down below.
					</p>
				</lang>
				<div class="faq" v-if="content">
					<faq-component v-for="(data, ndx) in faqData" :key="ndx" v-bind="data" />
				</div>
				<div v-else class="text-center">
					<fa-icon icon="faCog" spin size="lg"></fa-icon>
				</div>

				<lang id="faq-bottom"></lang>
				<!-- <router-link class="btn-support btn btn-black" :to="{name: 'contact-us'}">Contact Support</router-link> -->
			</div>
		</div>
	</div>
</template>

<script>
	import faqComponent from "../components/faq-component.vue";
	import store from "store";

	export default {
		name: "faq",

		components: {
			faqComponent,
		},
		methods: {
			isIe11() {
				return !!window.MSInputMethodContext && !!document.documentMode;
			},
			hashChangeHandler() {
				var target = window.location.hash;
				this.$router.push(target.substring(1, target.length));
			},
		},
		mounted() {
			// fix for IE 11 + vue-router > 2.7.0
			// @see https://github.com/vuejs/vue-router/issues/1911
			if (this.isIe11()) {
				window.addEventListener("hashchange", this.hashChangeHandler);
			}
		},
		destroyed() {
			// fix for IE 11
			if (this.isIe11()) {
				window.removeEventListener("hashchange", this.hashChangeHandler);
			}
		},
		data() {
			return {
				url: "faq",
				faqData: null,
				faqDataLastDownloaded: store.get("faq") || [],
			};
		},

		computed: {
			/**
			 * Return the last downloaded copy of the data.
			 * Issue a request to retrieve the latest data.
			 */
			content() {
				if (this.faqData) {
					return this.faqData;
				}
				this.$http
					.get(this.url)
					.then(
						(response) => {
							let data = response.data;
							if (typeof data=='string'){
							    data = null;
                            }
							this.faqData = data;
							this.faqDataLastDownloaded = data;
							store.set("faq", data);
						},
						(error) => {
							this.faqData = this.faqDataLastDownloaded;
						}
					)
					.catch((error) => {
						this.faqData = this.faqDataLastDownloaded;
					});
				return this.faqDataLastDownloaded;
			},
		},
		/* computed: {
					  faqs() {
						  let tmplts = [],
							  list = this.$store.get('global/getFaqs') || faqs;

						  for (let i = list.length - 1; i >= 0; i--) {
							  tmplts.unshift({
								  title: list[i].title,
								  body: list[i].body ? Vue.compile('<div class="collapse-inner">' + list[i].body + '</div>') : ''
							  });
						  }

						  return tmplts;
					  }
				  } */
	};
</script>

<style lang="scss" scoped>
</style>
