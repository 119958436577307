<template>
    <ul class="navbar-nav nav">
        <template v-for="(route, ndx) in items()">
            <li :key="ndx" :class="isActive(route.path)" class="nav-item" v-if="route.title">
                <router-link class="nav-link" :to="route.abspath || {name: route.name}" @click.native="$emit('close')" exact>{{ route.title }}</router-link>
            </li>
        </template>
    </ul>
</template>

<script>
import router from "../router";

export default {
    name: "app-nav",
    data() {
        return {};
    },

    methods: {
        items() {
            var res = [],
                addToMenu;

            for (var i = 0; i < router.options.routes.length; i++) {
                let route = router.options.routes[i];
                if (!route.meta || !route.meta.menuTitle) {
                    continue;
                }

                addToMenu = true;

                if (route.meta) {
                    if (typeof route.meta.inMenu === "function") {
                        addToMenu = route.meta.inMenu.call(this);
                    } else if (typeof route.meta.inMenu !== "undefined") {
                        addToMenu = route.meta.inMenu;
                    }
                    if (typeof route.meta.loggedIn !== "undefined") {
                        addToMenu =
                            addToMenu &&
                            (route.meta.loggedIn
                                ? this.$store.get("auth/isLoggedIn")
                                : this.$store.get("auth/isNotLoggedIn"));
                    }
                }

                if (addToMenu) {
                    res.push({
                        name:  route.name,
                        path:  route.path,
                        title: route.meta.menuTitle,
                        sort:  (route.meta && route.meta.sort) || 0,
                    });
                }
            }

            // add in external pages (server defined content)
            let pages = this.$store.get('auth/sessionData@pages');
            if (pages) {
                res.push(...pages)
            }

            res.sort((a, b) => {
                return a.sort - b.sort;
            });

            return res;
        },

        isActive(path) {
            // if (this.$route.path.indexOf(path) === 0)
            if (this.$route.path === path) {
                return "active";
            }
        },

        closeMenu() {
            $("body").removeClass("menu-open");
        },

        getLang() {
            return this.global.lang;
        },

        toggleLang() {
            if (this.global.lang === "en") {
                this.global.lang = "es";
            } else {
                this.global.lang = "en";
            }
        },
    },
};
</script>

<style lang="scss">
.navbar-toggler,
.navbar-brand {
    display: none;
}

.navbar-toggler {
    z-index: 1;
}

.navbar {
    .nav-link {
        line-height: 1.5;
        padding: 15px 25px;
        font-size: 30px;
        text-decoration: none;
        transition: 250ms;
        @include fontspecial;
        @media (min-width: $xxl) {
            padding: 15px 20px;
        }
    }
}

.nav {
    flex-direction: row;
}

.navbar-expand-lg .navbar-nav .nav-link{
    padding-left: 25px;
    padding-right: 25px;
}

.nav-cta{
    font-size: 25px;
    padding: 18px 35px;
}

@media(min-width: $collapse){
    .navbar-nav.nav {
        flex-direction: row;
        .nav-link{
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}

@media(max-width: $collapse - 1) {
    .navbar-toggler {
        display: block;
        z-index: 2;
    }
    .navbar-toggler {
        cursor: pointer;
        border-radius: 0;
        border: 0;
        padding: 11px 10px 12px;
        right: 0;

        &[aria-expanded="true"] {
            .icon-bar {
                opacity: 0;
            }

            .icon-bar-x {
                opacity: 1;
                transform: rotate(45deg);
                transition: 250ms 150ms;
            }

            .icon-bar-sneaky {
                transform: rotate(-45deg);
            }
        }

        .icon-bar {
            position: relative;
            width: 32px;
            height: 3px;
            opacity: 1;
            transform: rotate(0deg);
            transition: 250ms;
            top: 0;
            display: block;
            margin-top: 4px;
            background-color: $light;

            &:first-child {
                margin-top: 0;
            }

            &.icon-bar-sneaky {
                margin-top: -3px;
            }

            &:last-child {
                margin-bottom: 1px;
            }
        }
    }
    .navbar {
        justify-content: space-between;

        .main-menu {
            text-align: center;
            position: fixed;
            top: 95px;
            right: -100%;
            bottom: 0;
            overflow: auto;
            width: 100%;
            transition: opacity 250ms, right 0ms 250ms;
            opacity: 0;
            align-items: center;
            justify-content: center;
            z-index: 1;

            &.menu-open {
                display: flex;
                right: 0;
                opacity: 1;
                transition: opacity 500ms;
                background: $yellow;
                .employee & {
                    background: $darkblue;
                }
                .nav-link {
                    display: block;
                }
            }
        }

        .nav {
            flex-direction: column;
        }

        .nav-item {
            display: block;
        }
    }
}
</style>
