<template>
	<modal classes="" size="true" :showCloseX="false" title="Phone Number Required">
		<div slot="body">
			<div class="form-group">
				<label>To continue, please enter your phone number.</label>
				<input type="text" class="form-control" v-model="phone" autofocus>
				<div v-if="error" v-html="error" class="error"></div>
			</div>
		</div>
		<div slot="footer" class="btn-block">
			<button type="button" class="btn btn-block rounded candy-gradient-vertical color-light py-1 px-5 text-decoration-none" @click="submit" :disabled="sendIsDisabled" v-ada>Continue</button>
		</div>
	</modal>
</template>

<script>
    export default {
		name:     'getPhoneNumber',

		props: {
			modal: {
				type: Object,
				required: true
			}
		},

        data() {
            return {
                sending: false,
                phone:   '',
				error:false
            }
        },
        computed: {
            sendIsDisabled() {
                return this.sending || this.phone.length < 10;
            }
        },
        methods:  {
            test() {

            },
            submit() {
                if (this.sendIsDisabled) {
                    return;
                }

                this.sending = true;
                let data = {phone: this.phone};
                this.$http.post('/user/update', data).then(this.success).catch(this.failed);
            },
            success(response) {
                if (response.data && response.data.phone) {
					this.$store.commit('auth/SET_MISSING_PHONE_NUMBER', false);
					this.modal.trigger('close');
				}
            },
            failed(error) {
                this.sending = false;
                if (error.response.data && error.response.data.error){
                    this.error=error.response.data.error;
				}
            }
        }
    }
</script>
