<template>
	<div class="container-fluid px-0 pb-100 mt-4 bg-img-dark text-white">
        <h1 class="text-uppercase text-center py-5 my-2">Code History</h1>
        <div v-if="errorMessage" class="alert alert-warning col-sm-6 mx-auto mt-5">
            <div v-html="errorMessage"></div>
        </div>
        <div v-else-if="hasNoData()" class="text-center py-5">
            <lang id="code-history-no-data">Please wait...</lang>
        </div>
        <template v-else>
            <div class="container">
                <div class="d-flex flex-column bg-white rounded text-dark box-shadow-0-0-10">
                    <div class="row bg-yellow text-center mb-5 mx-0 rounded-top codes-played">
                        <div class="col-md-6 mb-3">
                            <div class="p-3">
                                <h2 class="text-dark font-size-xxl">
                                    {{ codes.winning.length + codes.losing.length }}
                                    <span class="font-size-largest d-block mt-2">Codes Submitted</span><span class="sr-only font-size-largest">.</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="p-3">
                                <h2 class="text-dark font-size-xxl">
                                    {{ codes.winning.length }}
                                    <span class="font-size-largest d-block mt-2">Prizes Won</span><span class="sr-only font-size-largest">.</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <form target="_blank" method="post" id="history-form" :action="submitUrl">
                        <input type="hidden" name="session_id" :value="sessionId">
                        <input type="hidden" name="_" :value="codeIdToPrint">
                        <input type="hidden" name="s" :value="specialPrizeIdToPrint">
                        <!-- region SWEEPSTAKES FORMS -->
                        <div class="p-3 p-md-5 mb-5" v-if="codes && codes.special">
                            <table class="table code-table code-table-winning text-dark" v-if="codes && codes.special">
                                <thead>
                                <tr>
                                    <th class="text-dark" colspan="2">Sweepstakes Form{{ Object.keys(codes.special).length > 1 ? 's' : '' }}</th>
                                    <th class="text-dark" width="290px">Date<span class="sr-only">.</span></th>
                                </tr>
                                </thead>
                                <tr v-for="item in codes.special" :key="item.id" :data-id="item.id">
                                    <td width="150">
                                        <button class="btn rounded candy-gradient-vertical color-light text-decoration-none py-1 px-5 btn-spend btn-sm" @click="printSpecialPrizeForm(item.id)">Print</button>
                                    </td>

                                    <!-- DESCRIPTION -->
                                    <td v-html="item.description"></td>

                                    <!-- DATE -->
                                    <td nowrap class="font-size-small">
                                        <span class="sr-only d-none d-md-inline">won on</span><strong class="d-md-none">Date Won:</strong> {{ item.created_at }}
                                    </td>

                                </tr>
                            </table>
                        </div>
                        <!-- endregion -->
                        <!-- region Winning Codes Entered -->
                        <div class="px-3 px-md-5" v-if="codes.winning && codes.winning.length">
                            <table class="table code-table code-table-winning text-dark" >
                                <thead>
                                <tr>
                                    <th class="text-dark" colspan="2">Winning Plays<span class="sr-only">.</span></th>
                                    <th class="text-dark" width="95px">Date<span class="sr-only">.</span></th>
                                    <th class="text-dark" width="195px">Code<span class="sr-only">.</span></th>
                                </tr>
                                </thead>
                                <tbody class="winning text-dark">
                                <tr v-for="item in codes.winning" :key="item.id" :data-id="item.id">

                                    <td width="150"><button class="btn rounded candy-gradient-vertical color-light text-decoration-none py-1 px-5 btn-spend btn-sm" @click="printPrizeForm(item.id)" v-if="item.form">Print</button></td>
                                    <!-- DESCRIPTION -->
                                    <td><strong class="d-md-none">Winning Play:</strong><div v-html="item.prize"></div>
                                    </td>

                                    <!-- DATE -->
                                    <td nowrap class="font-size-small"><strong class="d-md-none">Date:</strong> {{ item.created_at }}</td>
                                    <!-- CODE -->
                                    <td nowrap class="entered-code font-size-small" v-if="item.code">
                                        <span class="sr-only d-none d-md-inline">Code is</span><strong class="d-md-none">Code:</strong> {{ item.code }}
                                    </td>
                                    <td nowrap class="entered-code font-size-small" v-else></td>
                                </tr>
                                </tbody>
                            </table>


                            <!--region PRINT PRIZE FORMS BUTTON -->
                            <div v-if="false && showPrintButton" class="mt-5 text-center" tabindex="0">
                                <lang id="code-history-download-adobe-reader" class="font-size-small py-3">
                                    In order to view and/or print the prize forms you will need to download <a href="https://get.adobe.com/reader/" target="_blank">Adobe Reader</a>.
                                </lang>
                            </div>
                            <!-- endregion -->

                        </div>
                        <!-- endregion -->
                        <!-- region NON-WINNING CODES -->
                        <!-- todo make it match mockup -->
                        <div v-if="codes && codes.losing && codes.losing.length" class="code-table-losing-container px-3 px-md-5 mt-5 mb-4">
                            <table class="table table-sm code-table">
                                <thead>
                                <tr>
                                    <th class="text-dark">Non-Winning Plays</th>
                                    <th class="text-dark" width="95px">Date</th>
                                    <th class="text-dark" width="195px">Code</th>
                                </tr>
                                </thead>
                                <tbody class="losing text-dark">
                                <tr v-for="item in codes.losing" :key="item.id">
                                    <!-- DESCRIPTION -->
                                    <td class="d-md-none"><strong>Non-Winning Plays:</strong></td>
                                    <!-- DESCRIPTION -->
                                    <td class="font-size-small pl-1" v-html="item.description"></td>
                                    <!-- DATE -->
                                    <td nowrap class="font-size-small">
                                        <span class="sr-only d-none d-md-inline">entered on</span><strong class="d-md-none">Date Entered:</strong> {{ item.created_at }}
                                    </td>
                                    <!-- CODE -->
                                    <td nowrap class="entered-code font-size-small">
                                        <span class="sr-only d-none d-md-inline">Code is</span><strong class="d-md-none">Code:</strong> {{ item.code }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- endregion -->
                    </form>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import {get} from "vuex-pathify";
import vuexStore from '../store'
import {get as _get} from 'lodash';

export default {
    name: "history",

    data() {
        return {
            codes:                 null,
            submitUrl:             `${this.$config.serverUrl}/app/game/getPrizeForm`,
            checkedBoxes:          [],
            codeIdToPrint:         null,
            specialPrizeIdToPrint: null,
            errorMessage:null,
        };
    },
    computed: {
        sessionId: get("auth/session_id"),
        showPrintButton() {
            return (
                !this.$store.get("global/isApp") &&
                this.codes &&
                this.codes.winning &&
                this.codes.winning.some(code => code.form)
            );
        },
        nextDrawingTitle() {
            return _get(this.codes, 'next_drawing.title')
        },
        nextDrawings() {
            return _get(this.codes, 'next_drawing.drawings')
        }
    },
    created() {

        this.$http.get("/game/codes").then(
            response => {
                this.codes = response.data;
            }
        ).catch(xhrError=>{
            let msg = 'Code History is unavailable at this time. Please try again in a few minutes.';
            let detail=_get(xhrError, 'response.data', msg);
            if (process.env.NODE_ENV!=='production'){
                msg = detail;
            }
            this.errorMessage=msg;
        })
    },

    mounted() {
        this.watchConnectivity(true);
    },
    beforeDestroy() {
        this.watchConnectivity(false);
    },

    methods: {
        printSpecialPrizeForm(id) {
            this.specialPrizeIdToPrint = id;
        },
        printPrizeForm(id) {
            this.codeIdToPrint = id;
        },
        showCoupon(item) {
            this.$modalService.create('couponDetails', {info: item})
        },
        // selectItem(item) {
        //   if (item.form) {
        //     let id = item.id;
        //     let existing = this.checkedBoxes.indexOf(id);
        //     if (existing >= 0) {
        //       this.checkedBoxes.splice(existing, 1);
        //     } else {
        //       this.checkedBoxes.push(id);
        //     }
        //   }
        // },
        //
        prizesExist() {
            let prizeCount = 0;
            try {
                $.each(this.codes, (n, code) => {
                    if (this.hasPrizeForm(code)) {
                        prizeCount++;
                    }
                });
            } catch (ignore) {
            }
            return prizeCount > 0;
        },
        select(e) {
            let $el       = $(e.target),
                isChecked = $el.is(":checked");
        },

        hasCodes() {
            return (
                this.codes &&
                this.codes.winning &&
                this.codes.losing &&
                (this.codes.winning.length || this.codes.losing.length)
            );
        },
        hasNoCodes() {
            return (
                this.codes &&
                this.codes.winning &&
                this.codes.losing &&
                this.codes.winning.length === 0 &&
                this.codes.losing.length === 0
            );
        },
        hasNoData() {
            return this.codes === null;
        },
        submit() {
        },
        redeemUrl(uri) {
            let result;

            if (result = uri.match(/^\/?(#\/.+)$/)) {
                result = {
                    uri:        result[1],
                    isLocalUri: true
                };
            } else {
                result = {uri};
            }

            result.openInNewTab = !result.isLocalUri;

            return result;
        },
        clip(item, event) {
            if (item.busy) {
                return
            }
            item.busy = true;
            // todo add busy indicator state
            this.$http.get(`/game/clipCoupon/${item.id}`).then(response => {
                item.c2c = 'clipped';
            }, error => {
                this.$modalService.create('generic', {
                    title:   'Error',
                    message: 'Sorry, the request could not be completed at this time.'
                });
                // todo remove busy indicator state
                item.busy = false;
            })
        }
    },
    beforeRouteEnter(to, from, next) {
        if (!vuexStore.get('auth/allowCodeHistory')) {
            next({name: 'home'});
            return;
        }

        next();
    },
    watch: {
        checkedBoxes(to) {
            let message = `Print Selected Forms button ${to.length ? 'enabled' : 'disabled'}`;
            this.alertADA(message);
        }
    }
};
</script>
<style lang="scss" scoped>

caption {
    caption-side: top;
}

@media (max-width: $md - 1) {
    .table {
        @include responsive-font-size(14px);

        thead {
            display: none;
        }

        tr {
            border-bottom: 1px solid #FFFFFF33;
            position: relative;

            td {
                display: block;
                border: none;
                padding: 0;

                &:nth-child(1) {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                &:last-child {
                    padding-bottom: 20px;
                }
            }
        }
    }
}

.code-table-losing-container {
    max-height: 300px;
    overflow: auto;
    table {
        position: relative;
    }
}

.check-box {
    margin-top: 5px;
}

.card-event {
    line-height: 1.2;
}

.table-sm {
    td {
        padding: 5px;
    }
}

table {
    @include responsive-font-size(16px);

    thead {
        border: 0;

        th {
            position: sticky;
            top: 0;
            border: 0;
            text-transform: uppercase;
            border-bottom: none;
        }
    }

    td {
        padding: 20px 10px;
        vertical-align: middle;
        border-color: #ffffff33;

        &:first-child {
            padding: 0;

            a {
                margin-top: 1px;
            }
        }
    }

    .selectable {
        cursor: pointer;
    }

    .selected {
        color: $yellow;
    }
}

.text-underline {
    text-decoration: underline;
}

.btn-spend, .btn-clip {
    min-width: 7em;
}
.codes-played{
    .bg-green{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .employee & {
        background: $darkblue;
    }
    h2 {
        .employee & {
            color: $light!important;
        }
    }
}
</style>
