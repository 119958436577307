/**
 * These are static, environment-specific values.
 * For user-specific values refer to the global session store.
 *   for instance: $store.get('auth/sessionData')
 *
 * If `npm run build adaptive` was used then the runtime configuration will be changed
 * for QA or localdev, using the location.hostname. default configuration is production.
 * serverUrl - API URL
 *
 */

let config = {
	NODE_ENV:       'production',
	env:            'production',
	serverUrl:      'https://www.reasorspick3game.com',
	guestDomain:    'www.reasorspick3game.com',
	loggedInDomain: 'www.reasorspick3gamecodes.com',
	theme:          `reasors`,
	siteTitle:      "Reasor's Monopoly Pick 3 & Win",
	hostStoreUrl:   false,
	recaptcha:      {siteKey: '6Ldr0WIpAAAAABzbgJ1znCJ4hzOwpKPUMHXAaalJ'},
	useCaptcha:     true,
	useTrackers:    false,
};

if (/^qa\./.test(location.hostname)) {
	config.env = 'staging';
	config.serverUrl = 'https://qa.reasorspick3game.com';
	config.guestDomain = 'qa.reasorspick3game.com';
	config.loggedInDomain = 'qa.reasorspick3gamecodes.com';
}

if (/localhost|\.local$/.test(location.hostname)) {
	config.env = 'local';
	config.serverUrl = 'http://www.reasorspick3gamecodes.local';
	config.guestDomain = 'www.reasorspick3gamecodes.local';
	config.loggedInDomain = 'www.reasorspick3gamecodes.local';
	config.useCaptcha = false;
}


// for prod/qa/localdev, reasorspick3game can be thought of as the default domain and
// we adjust some configuration parameters here when on one of these different domains:
let defaultDomain = 'reasorspick3game';
let alternateDomains = {};

let themeOverride = null;
if (config.env === 'local') {
	let url = new URL(location.href);
	themeOverride = url.searchParams.get('theme');
}
for (let domain in alternateDomains) {
	if (location.hostname.indexOf(domain) >= 0) {
		config.serverUrl = config.serverUrl.replace(defaultDomain, domain);
		config.guestDomain = config.guestDomain.replace(defaultDomain, domain);
		config.loggedInDomain = config.loggedInDomain.replace(defaultDomain, domain);
		Object.assign(config, alternateDomains[domain]);
	}
}

if (themeOverride){
	console.log('the theme is now:', themeOverride);
	config.theme = themeOverride;
}
config.storeListUrl = config.serverUrl && `${config.serverUrl}/app/stores`;

// localUrl used to retrieve assets that could potentially be available locally if the app is running
config.localUrl = config.serverUrl;

config.usingLoggedInDomain = location.hostname === config.loggedInDomain;
config.usingGuestDomain = location.hostname === config.guestDomain;

// don't check and possibly redirect if the domain name is irrelevant (using app or localhost)
config.domainCheckRequired = (config.usingGuestDomain || config.usingLoggedInDomain) && config.loggedInDomain !== config.guestDomain;

export default config;
