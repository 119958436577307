<template>
    <section ref="sponsors" id="sponsors" class="sponsors" v-if="themes[$config.theme]">
        <div class="col-12 col-lg-10 offset-lg-1 py-0 py-md-3 py-lg-5">
            <div class="font-size-h3 text-dark text-center text-uppercase align-self-center pr-3"
            v-html="themes[$config.theme].headline"
            v-if="themes[$config.theme].headline"></div>
            <div class="d-flex justify-content-between flex-wrap ">
                <figure class="participant-logo" v-for="(logo, idx) in themes[$config.theme].logos.split(' ')" :key="idx">
                    <img :src="url(logo)" :alt="altText[logo]" class="img-fluid">
                </figure>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:    "sponsors",
    methods: {
        url(logo) {
            let filename = logo + '.jpg';
            let images = require.context('../assets/images/participants', false, /\.jpg$/);
            try {
                return images('./' + filename)
            } catch (error) {
                console.error(`Missing sponsor image: ${filename}`);
                return filename;
            }
            // require(`../assets/images/sponsors/${logo}.jpg`)
        },
    },
    data() {
        return {
            altText: {
                'coke':            `Coca Cola`,
                'dole':            `Dole`,
                'fritolay':        `Frito Lay`,
                'generalmills':    `General Mills`,
                'hiland':          `Hiland`,
                'kellanova':       `Kellanova`,
                'keurig':          `Keurig Dr Pepper`,
                'kraft':           `Kraft`,
                'monster':         `Monster`,
                'nabisco':         `Nabisco`,
                'pepsi':           `Pepsi`,
                'pg':              `P&G`,
                'purina':          `Purina`,
                'ragu':            `Ragú`,
                'reckitt':         `Reckitt`,
                'redbull':         `Red Bull`,
                'resers':          `Reser's`,
            },
            themes:  {
                reasors: {
                    headline: 'Participating Brands',
                    logos:    'nabisco coke pepsi purina kraft keurig fritolay monster pg dole resers generalmills redbull reckitt kellanova ragu hiland'
                },
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .participant-logo {
        width: 14vw;
        @media (min-width: $lg){
            width: 12vw;
        }
    }
</style>
