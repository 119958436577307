import cookie from 'js-cookie';
import config from '../startup/config';
import {EventBus} from './EventBus'

const myCookie = {
	get(name) {
		return new Promise((resolve) => {

				resolve(cookie.get(name));

		});
	},

	set(name, value, opts) {
		return new Promise((resolve, reject) => {

				cookie.set(name, value, opts || {});
				resolve();

		});
	}
};

export default myCookie;
