<template>
    <div class="reset-password pt-4 pb-100">
        <div class="container">
			<div class="d-flex flex-column p-4 p-xl-5 bg-white rounded text-dark box-shadow-0-0-10">
                <div class="font-weight-bold pb-3">Reset Password</div>
                <p class="small text-dark">To change your password, enter a new password below.</p>
                <form method="post" action="" @submit.prevent="submit">
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-danger" v-if="error.errorMessage" v-html="error.errorMessage"></div>
                            <b-form-group id="password" label="Password" label-for="password-input" aria-describedby="password-feedback">
                                <b-form-input v-model="form.password" id="password-input" type="password" ref="password" :state="isError(error.password)"
                                              autocomplete="new_password"></b-form-input>
                                <b-form-invalid-feedback id="password-feedback" v-html="error.password"></b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group id="confirm-password" label="Confirm Password" label-for="confirm-password-input"
                                          aria-describedby="confirmPassword-feedback">
                                <b-form-input v-model="form.confirmPassword" id="confirm-password-input" type="password" ref="confirm-password" :state="isError(error.confirmPassword)"
                                              autocomplete="new_confirm"></b-form-input>
                                <b-form-invalid-feedback id="confirmPassword-feedback" v-html="error.confirmPassword"></b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <submit-button :loading="busy" type="submit" class="btn btn-rounded candy-gradient-vertical color-light py-1 px-5 mt-4">Reset Password</submit-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {get} from 'lodash';
import config from '../startup/config'

export default {
    name:    "reset-password",
    data() {
        return {
            busy: false,
            form:  {
                token:           this.$route.params.token,
                password:        '',
                confirmPassword: '',
            },
            error: {
                password:        null,
                confirmPassword: null
            }
        }
    },
    methods: {
        isError(value) {
            if (typeof value === 'undefined' || value === null) {
                return null;
            }
            return !value;
        },
        submit() {
            this.busy=true;
            this.$http.post('/user/resetPassword', this.form)
                .then(this.resetPasswordSuccess, this.resetPasswordFailure)
        },
        resetPasswordSuccess(user) {

            let redirect = [
                location.protocol,
                '//',
                config.loggedInDomain
            ];
            if (location.port) {
                redirect.push(`:${location.port}`)
            }
            redirect.push('/enter-code');
            redirect.push('?sessionid='+user.data.user.ephemeral_login_token)
            redirect = redirect.join('');
            location.href=redirect;
        },
        resetPasswordFailure(xhr) {
            this.busy=false;
            let error = get(xhr, 'response.data.error', null);
            if (error) {
                console.error(error)
                this.error = error;
                this.form.password='';
                this.form.confirmPassword = '';
            }
            this.$refs.password.focus();
        }
    }
};
</script>

<style lang="scss" scoped>
.alert{
    a{
        font-weight: bold;
        color: #491217;
    }
}
</style>
