<template>
    <div class="container-fluid reg-box">
        <div class="row justify-content-center">
            <div class="col-sm-8">
                <div class="bg-white d-flex flex-column mx-auto mb-100 mt-4 rounded p-4 p-xl-5 box-shadow-0-0-10">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="w-100">
                                <div class="font-weight-bold pb-3">Register</div>
                                <p class="small text-dark font-italic mb-3">All fields marked with * are required</p>
                                <FormulateForm
                                    name="registrationForm"
                                    @submit="submit"
                                    v-model="formData"
                                    class="form mb-5"
                                    :form-errors="formErrors"
                                    :errors="formFieldErrors"
                                >
                                    <FormulateInput
                                        name='first_name'
                                        label='First Name*'
                                        placeholder='First name'
                                        type='text'
                                        validation='required'
                                        validationName='First name'
                                        value=''
                                        class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                        label-position='before'
                                    />
                                    <FormulateInput
                                        name='last_name'
                                        label='Last Name*'
                                        placeholder='Last name'
                                        type='text'
                                        validation='required'
                                        validationName='Last name'
                                        value=''
                                        class='col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top'
                                    />
                                    <FormulateInput
                                        name='email'
                                        label='Email*'
                                        placeholder='Email'
                                        type='text'
                                        validation='email'
                                        value=''
                                        class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                    />
                                    <FormulateInput
                                        name='phone'
                                        label='Phone*'
                                        placeholder='xxx-xxx-xxxx'
                                        type='text'
                                        validation='min:10,length'
                                        validationName='Contact phone number with area code'
                                        value=''
                                        class='col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top'
                                    />
                                    <FormulateInput
                                        name='password'
                                        label='Create Password*'
                                        type='password'
                                        validation='required|min:8,length'
                                        value=''
                                        class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                    />
                                    <FormulateInput
                                        name='password_confirm'
                                        label='Verify Password*'
                                        type='password'
                                        validation='confirm'
                                        validationName='The password you entered'
                                        value=''
                                        class='col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top'
                                    />
                                    <FormulateInput
                                        name='address'
                                        label='Address*'
                                        type='text'
                                        validation='required'
                                        validationName='Address'
                                        value=''
                                        class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                    />
                                    <FormulateInput
                                        name='city'
                                        label='City*'
                                        type='text'
                                        validation='required'
                                        validationName='City'
                                        value=''
                                        class='col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top'
                                    />
                                    <FormulateInput
                                        name='state'
                                        label='State*'
                                        type='select'
                                        :options='states'
                                        validation='required'
                                        validationName='State'
                                        value=''
                                        class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                    />
                                    <FormulateInput
                                        name='zip'
                                        label='ZIP*'
                                        type='text'
                                        validation='required'
                                        validationName='ZIP Code'
                                        value=''
                                        class='col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top'
                                    />
                                    <FormulateInput
                                        name='store_id'
                                        label='Your Store*'
                                        type='storeChooser'
                                        validation='required'
                                        validationName="Store"
                                        value=''
                                        class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                    />
                                    <FormulateInput
                                        name='old_enough'
                                        label='I am at least eighteen (18) years of age or older*'
                                        validationName='age requirement'
                                        type='checkbox'
                                        validation='accepted'
                                        value=''
                                    />
                                    <FormulateInput
                                        name='agree_to_terms'
                                        validationName='rules and regulations'
                                        type='checkbox'
                                        validation='accepted'
                                        value=''>
                                        <template #label="{ id }">
                                            <label :for="id" class="formulate-input-label formulate-input-label--after">
                                                I have read and agree to the <router-link to="/rules" v-on:click.native="toggleMenu(false)">Official Rules</router-link>.*
                                            </label>
                                        </template>
                                    </FormulateInput>
                                            
                                    <recaptcha3 v-if="$config.useCaptcha" :siteKey="$config.recaptcha.siteKey" ref="captchaComponent" context="register"></recaptcha3>
                                    <submit-button class="btn btn-rounded candy-gradient-vertical color-light py-1 px-5 mt-4 d-inline-block" id="reg-submit" ref="btnSubmit" :loading="busy">Submit</submit-button>
                                    <div class="align-middle mt-4 float-md-right text-md-right">
                                        <div class="font-size-sm">Already Registered?
                                            <router-link :to="{name:'returning-player'}">Sign in</router-link>
                                        </div>
                                    </div>
                                </FormulateForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- eod .row -->
    </div>
</template>
<style lang="scss" scoped>
#main {
    min-height: none;
}

.reg-banner {
    background: url(../assets/images/bg-home.jpg) center 80%/cover repeat-y;

}
</style>
<script>

import {get} from 'lodash'

const stateOptions = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
import vuexStore from '../store';
import Recaptcha3 from '../components/Recaptcha3';
import FormInputDOB from '../components/FormInputDOB';
import RouterLinkAda from '../components/router-link-ada.vue';

export default {
    name:       "new-player",
    components: {
        Recaptcha3,
        FormInputDOB,
    },
    beforeRouteEnter(to, from, next) {
        if (!vuexStore.get('auth/allowRegister')) {
            next({name: 'home'});
            return;
        }
        next();
    },
    data() {
        return {
            busy: false,
            //https://vueformulate.com/guide/validation/#customize-validation-messages
            registrationForm: [
                {
                    name:       'email',
                    label:      'Email (Must be a valid email address)*',
                    type:       'text',
                    validation: 'email',
                    value:      ''
                },
                {
                    name:           'email_confirm',
                    label:          'Verify Email*',
                    type:           'text',
                    validation:     'confirm',
                    validationName: 'Confirmation email',
                    value:          ''
                }, {
                    name:       'password',
                    label:      'Password*',
                    type:       'password',
                    validation: 'required|min:6,length',
                    value:      ''
                }, {
                    name:           'password_confirm',
                    label:          'Re-Type Password*',
                    type:           'password',
                    validation:     'confirm',
                    validationName: 'The password you entered',
                    value:          ''
                },
                {
                    name:           'first_name',
                    label:          'First Name*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'First name',
                    value:          ''
                },
                {
                    name:           'last_name',
                    label:          'Last Name*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'Last name',
                    value:          ''
                },
                {
                    name:           'address',
                    label:          'Address*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'Address',
                    value:          ''
                },
                {
                    name:           'city',
                    label:          'City*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'City',
                    value:          ''
                },
                {
                    name:           'state',
                    label:          'State*',
                    type:           'select',
                    options:        stateOptions,
                    validation:     'required',
                    validationName: 'State',
                    value:          ''
                },
                {
                    name:           'zip',
                    label:          'ZIP*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'ZIP Code',
                    value:          ''
                },
                {
                    name:           'phone',
                    label:          'Contact Phone Number*',
                    type:           'text',
                    validation:     'min:10,length',
                    validationName: 'Contact phone number with area code',
                    value:          ''
                },
                {
                    // see vue-addons.js for component registration of FormInputChooseStore
                    name:           'store_id',
                    label:          'Select your store*',
                    type:           'storeChooser',
                    validation:     'required',
                    validationName: "Store",
                    value:          ''
                },
                {
                    name:           'old_enough',
                    label:          'I am 18 years of age or older*',
                    validationName: 'age requirement',
                    type:           'checkbox',
                    validation:     'accepted',
                    value:          ''
                },
                {
                    name:  'newsletter',
                    label: 'I wish to receive future communication from my selected supermarket.',
                    type:  'checkbox',
                    value: ''
                },
                {
                    name:           'agree_to_terms',
                    label:          'I agree to the Rules & Regulations.*',
                    validationName: 'rules and regulations',
                    type:           'checkbox',
                    validation:     'accepted',
                    value:          ''
                }
            ],
            formErrors:       [],
            formData:         {},
            states:           stateOptions,
            formFieldErrors:  {}
        }
    },
    mounted() {
        this.formData = this.formDefaultValues;
    },
    computed: {
        formDefaultValues() {
            return {};
            // let data = {};
            // if (this.$config.env === 'local') {
            //     try {
            //         data = require('../defaults/new-player-test-data.json');
            //         console.log('using test data:', data);
            //     } catch (err) {
            //     }
            // }
            // return data;
        }
    },
    methods:  {
        submit(data) {
            this.busy = true;
            let getCaptchaToken = new Promise((resolve) => {
                if (this.$refs.captchaComponent) {
                    this.$refs.captchaComponent.getToken().then(token => {
                        data['captcha'] = token;
                        resolve(token);
                    })
                } else {
                    resolve(true);
                }
            })
            console.log(data);
            let registerUser = () => {
                this.$http.post('user/register', data).then(response => {
                    if (response) {
                        this.$root.$children[0].loginUserAndEnterCodes(response.data);
                    } else {
                        if (this.$refs.captchaComponent) {
                            this.$refs.captchaComponent.reset();
                        }
                    }
                }).catch(({response}) => {
                    console.log('error response=', response);
                    this.busy = false;
                    let formFieldError = get(response, 'data.error');
                    let formError = get(response, 'data.error.errorMessage', 'Your request could not be processed at this time.');

                    if (formFieldError) {
                        this.formFieldErrors = response.data.error;
                    }
                    this.formErrors = [formError];
                }).finally(_ => {
                    console.log('reset captcha ');
                    if (this.$refs.captchaComponent) {
                        this.$refs.captchaComponent.reset();
                    }
                });
            }
            getCaptchaToken.then(registerUser);

        },
        test() {
            this.formData = this.testData;
        }
    },

};
</script>
