<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-sm-8">
                <div class="bg-white d-flex flex-column mx-auto mb-100 mt-4 rounded p-4 p-xl-5 box-shadow-0-0-10">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="" v-if="startDate && $store.get('auth/isLoggedIn')">
                                <div class="text-center">
                                Thank you for registering! Come back {{ whenGameStarts }} to start entering codes for a chance to win!
                                </div>
                            </div>
                            <div class="w-100" v-else>
                                <div class="font-weight-bold p-3 bg-yellow rounded mb-5 text-center"><router-link :to="{name:'new-player'}">Sign up</router-link> for a Monopoly Pick 3 & Win account to play. This is separate from your reasors.com account!</div>
                                <div class="font-weight-bold pb-3">Sign in</div>
                                <FormulateForm
                                    name="loginForm"
                                    class="mb-5"
                                    @submit="submit"
                                    :schema="loginForm"
                                    v-model="formData"
                                    :form-errors="formErrors"
                                >
                                    <recaptcha3 ref="captchaComponent" context="login" v-if="$config.useCaptcha" :siteKey="$config.recaptcha.siteKey"></recaptcha3>
                                    <submit-button class="btn btn-rounded candy-gradient-vertical color-light py-1 px-5 mt-4 d-inline-block" ref="btnSubmit" :loading="busy">Sign In</submit-button>
                                    <div class="align-middle mt-4 float-md-right text-md-right">
                                        <div class="font-size-sm mb-2">Having Trouble?
                                            <router-link :to="{name:'forgot-password'}">Recover password</router-link>
                                        </div>
                                        <div class="font-size-sm">Don't have an account?
                                            <router-link :to="{name:'new-player'}">Sign up</router-link>
                                        </div>
                                    </div>
                                </FormulateForm>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import Recaptcha3 from '../components/Recaptcha3';
import config from '../startup/config'
import {get} from 'lodash'
import {format, parseISO} from 'date-fns';
export default {
    name:       "returning-player",
    components: {Recaptcha3},
    data() {
        return {
            busy: false,
            //https://vueformulate.com/guide/validation/#customize-validation-messages
            loginForm:  [
                {
                    name:        'email',
                    label:       'Email',
                    placeholder: 'Email',
                    type:        'text',
                    validation:  'email',
                    class:       'col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top',
                    value:       ''
                },
                {
                    name:        'password',
                    label:       'Password',
                    placeholder: 'Password',
                    type:        'password',
                    validation:  'required',
                    class:       'col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top',
                    value:       ''
                }
            ],
            formErrors: [],
            formData:   {}
        }
    },
    computed:{
      startDate() {return this.$store.get('auth/sessionData@gameStarts')},
        whenGameStarts(){
          let d = this.$store.get('auth/sessionData@gameStarts');
          if (!d) return null;
         let theDate=parseISO(d);
         return format(theDate, 'MMMM do')
        }
    },
    methods: {
        submit(data) {
            this.busy = true;
            let getCaptchaToken = new Promise((resolve) => {
                if (this.$refs.captchaComponent) {
                    this.$refs.captchaComponent.getToken().then(token => {
                        data['captcha'] = token;
                        resolve(token);
                    })
                } else {
                    resolve(true);
                }
            })
            let loginUser = () => {
                this.$http.post('user/login', data).then(response => {
                    if (response) {
                        this.$root.$children[0].loginUserAndEnterCodes(response.data);
                    }
                }).catch(({response}) => {
                    this.busy = false;
                    let formError = get(response, 'data.error.errorMessage', 'Your request could not be processed at this time.');
                    if (formError) {
                        this.formErrors = [formError];
                    }
                }).finally(_ => {
                    console.log('reset captcha ');
                    if (this.$refs.captchaComponent) {
                        this.$refs.captchaComponent.reset();
                    }
                });
            }
            getCaptchaToken.then(loginUser);
        }
    }
};
</script>

<style lang="scss" scoped>
    .winner-box {
        width: 100%;
        margin-bottom: 5rem;
		@media(min-width: $md) {
            display: flex;
            flex-direction: column;
            flex: 1 1 0;
            justify-content: center;
            width: auto;
            min-width: 40%;
        }
		@media(min-width: $xl) {
            min-width: 0;
            margin-bottom: 3rem;
        }
        img {
            position: absolute;
            top: -50px;
            left: 0;
            right: 0;
            margin: auto;
        }
        &:first-child {
            img {
                top: -100px;
            }
        }
        &:last-child {
            img {
                top: -60px;
            }
        }
    }
</style>
