<template>
	<div id="app" :data-user="this.$store.get('auth/currentUserEmail')" :class="bodyClasses" v-show="readyToShowView">
		<header class="header" v-ada="['modal-non-interactive']">

			<!--
				Lets hold off on this unless they ask. Skips links are needed as long as all content is contained in the proper elements like we're doing.
				<div class="accessibility-header position-absolute">
				<button class="btn btn-nav btn-primary sr-only sr-only-focusable route-focus" type="button" @click="adaFocus($event, true)" data-target="#navigation" v-ada>Skip to Navigation
				</button>
				<button class="btn btn-nav btn-primary sr-only sr-only-focusable" type="button" @click="adaFocus($event)" data-target="#main" v-ada>Skip to Main Content</button>
				<button class="btn btn-nav btn-primary sr-only sr-only-focusable" type="button" @click="adaFocus($event)" data-target="#footer" v-ada>Skip to Footer</button>
			</div> -->

			<div class="header-top d-flex align-items-center">
				<div class="container-fluid d-flex align-items-center justify-content-center">
                    <button class="navbar-toggler mr-auto"
                            type="button"
                            data-target="#main-menu"
                            aria-controls="main-menu"
                            aria-expanded="false"
                            @click="toggleMenu"
                            ref="navbarToggler">
                        <i class="icon-bar"></i>
                        <i class="icon-bar icon-bar-x"></i>
                        <i class="icon-bar icon-bar-x icon-bar-sneaky"></i>
                        <i class="icon-bar"></i>
                        <span class="sr-only">Menu</span>
                    </button>

                    <nav id="navigation" class="navbar flex-shrink-0 p-0" ref="nav">
                        <div class="main-menu" id="main-menu">
                            <app-nav v-on:close="toggleMenu(false)"></app-nav>
                        </div>
                    </nav>

                    <router-link class="btn btn-rounded candy-gradient-vertical color-light py-1 px-5 cta-btn shadow-sm" :to="{name:'enter-code'}" v-if="showEnterCodeButton" v-on:click.native="toggleMenu(false)">Enter Code</router-link>

					<router-link class="btn btn-rounded candy-gradient-vertical color-light py-2 px-5 cta-btn" :to="{name:'returning-player'}" v-else-if="showPlayTheGameButton">Play the game</router-link>

				</div>
			</div>
		</header>

        <!-- removed modal-non-interactive to fix some bugs -->
		<main ref="main" id="main" v-ada="['menu-non-interactive']" data-anchor="main" :class="{ 'is-in-match' : isInMatch }">
            <div class="d-flex align-items-center justify-content-center mt-0 mt-md-5 mb-2" v-if="!isInMatch">
                <img :src="require(`./assets/images/logo-header.png`)" alt="Reasor's Monopoly Pick 3 and Win" class="img-fluid">
            </div>
			<router-view v-if="readyToShowView" ref="view"></router-view>
			<modal-service-component></modal-service-component>
			<lang id="app-style"></lang>
			<p class="sr-only" aria-live="assertive" aria-atomic="true">{{$store.get('ada/alert')}}</p>
		</main>

        <sponsors v-if="!isInMatch"></sponsors>

		<footer v-if="!isInMatch" id="footer" class="footer" v-ada="['modal-non-interactive', 'menu-non-interactive']" data-anchor="footer">
			<div class="container text-center py-4">
				<lang id="footer-legal" class="color-white font-size-sm">
					Copyright 2024. All Rights Reserved.
                    <a href="https://www.reasors.com/privacy-policy" class="font-weight-normal" target="_blank">Privacy Policy</a> | 
                    <a href="https://www.reasors.com/terms-of-use" class="font-weight-normal" target="_blank">Terms of Use</a>
				</lang>
			</div>
		</footer>
	</div>
</template>

<script>
	import "./assets/js/jquery.matchHeight.min";
	import "./assets/js/smooth-scroll";
	import "./assets/js/jquery.ada-navigation";

	import cookie from "./utils/cookie";

	import ModalServiceComponent from "./components/ModalServiceComponent";
	import AppNav from "./components/AppNav";
	import Countdown from "./components/countdown";
    import {ModalService} from './utils/ModalService';
    import sponsors from './components/sponsors';
	export default {
		name: "app",

		components: {
			AppNav,
			ModalServiceComponent,
			Countdown,
            sponsors
		},

		data() {
			return {
				// This should allow individual pages control over connectivity issues
				watchConnectivityState: false,

				hideAppPromoRefresh: false,
				backPressedQuit: false,
				tickerStyleTag:
					window.navigator.userAgent.indexOf("MSIE ") > 0 && false,
				currentTime: $.now() / 1000,
			};
		},

		asyncComputed: {
			hideAppPromo: {
				get() {
					return new Promise((resolve) => {
						if (process.appVersion) {
							resolve(true);
						} else if (this.$route.name === "download-app") {
							resolve(true);
						} else {
							cookie.get("hide-app-promo").then(resolve);
						}
					});
				},
				watch() {
					return this.hideAppPromoRefresh;
				},
			},
		},

		computed: {
		    showPlayTheGameButton(){
		        // not logged in, are allowed to login/register, and not already on the login/register page
                return !this.$store.get("auth/isLoggedIn") &&
                    this.$store.get('auth/allowLoginOrRegister') &&
                    ['returning-player', 'new-player', 'home'].indexOf(this.$route.name)<0
            },
            readyToShowView() {
                return this.$store.get('auth/ready') && this.$store.get('router/ready2');
            },
			showSpendTokensButton() {
				let allow = this.$store.get("auth/allowSpendTokens");
				allow = allow && this.$route.name !== "rewards-and-sweepstakes";
				return allow;
			},
			showEnterCodeButton() {
				let allow = this.$store.get("auth/allowEnterCodes");
				allow = allow && this.$route.name !== "enter-code" && this.$route.name !== 'match';
				return allow;
			},
			isInMatch(){
				return this.$route.name === 'match';
			},
			showFooterMessages() {
				return (
					this.$config.env === "local" &&
					!this.$store.get("global/isApp") &&
					this.$store.get("auth/messages").length
				);
			},
			showAppPromoSection() {
				return !this.$store.get("global/isApp") && !this.hideAppPromo;
			},
			bodyClasses() {
                let $body = $('body');
                $body.attr('data-theme', this.$config.theme);
                $body.toggleClass('overflow-hidden', !this.readyToShowView);
                $body.toggleClass('show', this.readyToShowView);
                $body.removeClass('customer employee associate');
                let currentUserClass=(this.$store.get("auth/currentUserType")||'').toLowerCase();
                $body.addClass(currentUserClass);
                let showHeaderBottom = this.$store.get('auth/showRemainingTokens')|| this.$store.get("global/screenWidth") < 1200;
				return {
					"logged-in": this.$store.get("auth/isLoggedIn"),
					tester: this.$store.get("auth/isTester"),
					"show-header-bottom": showHeaderBottom,
					"ad-blocker-detected": this.$store.get(
						"global/adBlockerEnabled"
					),
					"has-notice": this.$store.get("auth/messages").length,
					[(
                        currentUserClass
					).toLowerCase()]: true,
				};
			},

			tickerStyle() {
				let style = {
					"padding-left": this.$store.get("global/screenWidth") + "px",
				};

				return style;
			},

			modalsVisible() {
				return !!this.$modalService.getVisibleModals().length;
			},


			showCountdown() {
				return (
					this.$store.get("auth/sessionData@gameStarts") &&
					!this.$store.get("global/isApp")
				);
			},
			showUpcomingSweepstakes() {
				let s = this.$store.get("auth/sweeps");
				return s && Object.keys(s).length > 0;
			},
            showMobileNavToggle() {
		        console.log('showMobileNavToggle',this.$route.name);
		        return this.$route.name!=='home';
            },
            headerBottomClasses() {
                let showTokenBar = this.$store.get('auth/showRemainingTokens');
                if (showTokenBar) {
                    return null;
                }
                // only visible if < xl
                return 'd-xl-none';
            }
        },

		created() {
			$("body").attr("tabindex", 0);

			window.state = this.$store.state;
            let loadContentFromLangFile = false;

				this.$EventBus.$emit("deviceready");

            // Start loading language files
            if (loadContentFromLangFile) {
                this.$store.dispatch("refreshJsonFiles");
            }

			this.$watch(
				"$route",
				(to, from) => {
					this.backPressedQuit = false;

					from = from || { name: "unknown" };
					to = to || { name: "unknown" };
					let toClassName = to.name
							? to.name.toLowerCase().replace(/[^\w-]/g, "-") +
							  "-route"
							: "home-route",
						fromClassName = from.name
							? from.name.toLowerCase().replace(/[^\w-]/g, "-") +
							  "-route"
							: "home-route";
					$("body").removeClass(fromClassName).addClass(toClassName);
				},
				{ immediate: true }
			);

			let body = $("body");

			body.toggleClass("is-mobile", this.$store.get("global/isMobile"));
			if (this.$config.env !== "production") {
				body.addClass(this.$config.env + "-env");
			}

			let e = window,
				a = "inner";
			if (!("innerWidth" in window)) {
				a = "client";
				e = document.documentElement || document.body;
			}

			$(window)
				.resize(() => {
					let width = e[a + "Width"];
					this.$store.set("global/screenWidth", width);
				})
				.resize();

			// Listen for postMessages
			window.addEventListener(
				"message",
				(event) => {
					this.$EventBus.$emit("postMessage", event);
				},
				false
			);

			/** Listen for code scan events
			 *  Tried using the $EventBus and also document level events, but
			 *  the $modalService would not work and $EventBus tends to queue
			 *  up events for some unknown reason.
			 * @param code
			 */
			// window.sendBarcode = (code) =>{
			// 1. redirect user to scan-code page
			// 2. ajax submit the code to the server
			// this.$router.push({name:'scan-code', params:{code:code}});
			// }
		},

		mounted() {
			$(this.$refs.nav).ADANavigation(null, function (e) {
				return $("#main-menu").hasClass("menu-open");
			});

			// Detect ad blockers
			window.blockAdBlock = new BlockAdBlock({

			});

			blockAdBlock
				.onDetected(() => {
					// this.$store.commit("setAdBlockerEnabled");
				})
				.check();

			// conditionally create IE style tag
			// if (this.tickerStyleTag) {
			//   this.tickerStyleTag = $("<style/>").appendTo("body");
			// }

			// Wait for component to be rendered first
			// $(document).on("fully-rendered", ".ticker", () => {
			//   this.updateTickerAnimationSpeed();
			// });


			$(document).on("offline online", (e) => {
				console.log("offline state change", e);
				this.$store.set("global/offline", e.type === "offline");
			});

			this.$watch(
				() => {
					return this.$store.get("auth/isLoggedIn");
				},
				(to) => {
					if (to) {
                        // $(this.$refs.main).css("min-height", "");
                    } else {
						let minHeight = $(window).height() - $("footer").height();
						// $(this.$refs.main).css("min-height", `${minHeight}px`);
					}
				},
				{ immediate: true }
			);

			// Stupid iframe trackers
			setInterval(() => {
				this.disableTabIframes();
			}, 1000);
		},

		methods: {
		    /**
             * Use the sessionData.redirect if it is specified, otherwise construct it here.
             */
            loginUserAndEnterCodes(sessionData){
                let redirect = [
                    location.protocol,
                    '//',
                    this.$config.loggedInDomain
                ];
                if (location.port) {
                    redirect.push(`:${location.port}`)
                }
                redirect.push('/enter-code');
                redirect.push('?sessionid=' + sessionData.user.ephemeral_login_token)
                redirect = redirect.join('');
                if (sessionData.redirect){
                    redirect=sessionData.redirect;
                }
                if (sessionData.redirect_reason){
                    let modal = {
                        message: sessionData.redirect_reason,
                        buttons:[
                            {label: 'Continue'}
                        ],
                        opts:{
                            showCloseX:false,
                            showHeader:false,
                            contentClasses:'theme-box'
                        }
                    }
                    this.$modalService.create('generic', modal).on('Continue close', ()=>{
                        top.location.href = redirect;
                    })
                } else {
                    location.href = redirect;
                }
            },

            /**
			 * toggleMenu(false) is invoked when an item is clicked from the nav menu
			 * @param show
			 */
			toggleMenu(show) {
				var self = $(this.$refs.navbarToggler);
				var target = $(self.data("target"));
				let open = self.attr("aria-expanded") == "true";
				if (typeof show === "boolean") {
					open = !show;
				}
				let $nav = $(this.$refs.nav);
				if (open) {
					self.attr("aria-expanded", "false");
					$nav.off("keydown");
					self.find(".sr-only").text("Show Navigation");
					target.attr("aria-hidden", "true");
				} else {
					self.attr("aria-expanded", "true");
					self.find(".sr-only").text("Close Button");
					target.removeAttr("aria-hidden");
				}

				target.toggleClass("menu-open", !open);
				$("body").toggleClass("main-menu-open", !open);
				this.$store.set("global/navExpanded", !open);
				if (show === false) {
					let focusable = $("body, .route-focus");
					if (navigator.userAgent.match(/iPad|iPhone/i)) {
						// all iPhone/iPad browsers, because VoiceOver sucks
						focusable = focusable.filter(
							"a, button, input, select, textarea"
						);
					}
					focusable.first().focus();
				}
			},

			dismissAppPromo() {
				cookie.set("hide-app-promo", true, { expires: 7 }).then(
					() => {
						this.hideAppPromoRefresh = !this.hideAppPromoRefresh;
					},
					() => {
						// unable to write cookie
					}
				);
			},
			backButtonRaw() {
				let options = {
					cancel: false,
				};

				this.$EventBus.$emit("backbutton", options);

				if (!options.cancel) {
					this.backButton();
				}
			},
			backButton() {
				// Unfortunately, this completely overrides default functionality.
				// We must do history.back() to retain default action

				// Close a modal if possible
				if (!this.$modalService.close()) {
					if (
						this.$route.name === "home" ||
						this.$route.name === "enter-code"
					) {
						if (this.backPressedQuit) {
							navigator.app.exitApp();
						} else {
							this.backPressedQuit = true;
							plugins.toast.showShortBottom(
								"Press the back button again to exit"
							);
							setTimeout(() => {
								this.backPressedQuit = false;
							}, 3000);
						}
					} else {
						history.back();
					}
				}
			},
			updateTickerWidth() {
				let ticker = $(".ticker");
				let width = 0;
				ticker.children().each((k, item) => {
					width += $(item).outerWidth();
				});
				ticker.width(width);
			},
			updateTickerAnimationSpeed() {
				this.updateTickerWidth();

				let ticker = $(".ticker"),
					fullWidth = ticker.outerWidth();
				ticker.css("animation-duration", fullWidth / 200 + "s");

				if (this.tickerStyleTag) {
					this.tickerStyleTag.html(
						"@keyframes ticker {\n" +
							"\t0% {\n" +
							"\t\ttransform: translate3d(0, 0, 0);\n" +
							"\t\tvisibility: visible;\n" +
							"\t}\n" +
							"" +
							"\t100% {\n" +
							"\t\ttransform: translate3d(-" +
							fullWidth +
							"px, 0, 0);\n" +
							"\t}\n" +
							"}"
					);
				}
			},
			adaFocus(e, dontScroll) {
				let hash = $(e.target).data("target"),
					target = $(hash);

				let focusable =
					'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';
				target = target.find(focusable);
				if (navigator.userAgent.match(/iPad|iPhone/i)) {
					// all iPhone/iPad browsers, because VoiceOver sucks
					target = target.filter("a, button, input, select, textarea");
				}
				target = target.first();

				if (dontScroll) {
					target.focus();
				} else {
					$.smoothAnchor(hash).then(() => {
						target.focus();
					});
				}
			},

			disableTabIframes() {
				$("body > iframe").attr({
					tabindex: "-1",
					"aria-hidden": "true",
				});
			},
		},

		watch: {
			tickerStyle() {
				this.$nextTick(() => {
					this.updateTickerAnimationSpeed();
				});
			},
		},
	};
</script>

<style lang="scss">
	@import "~bootstrap/dist/css/bootstrap.min.css";
	@import "../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
	@import "~ladda/css/ladda.scss";
	@import "~slick-carousel/slick/slick.css";
	@import "./assets/css/style.scss";
</style>

<style lang="scss" scoped>
	.header-top {
		height: $headerTopHeight;
	}

	.site-logo {
        width: 100%;
		height: auto;
		@media(min-width: $md) {
			max-width: 100%;
		}
	}

	.header-bottom {
		height: $headerBottomHeight;
		padding: 0 15px;
		color: $darkgreen;
		font-weight: 900;
	}

	.accessibility-header .sr-only-focusable {
		&:focus {
			padding: 6px !important;
			z-index: 1;
			@media (min-width: $sm) {
				position: relative;
				left: 320px;
				top: -25px;
			}
		}
	}

	.col-phone {
		text-align: center;
		img {
			position: relative;
			max-width: 100px;
			box-shadow: 7px 10px 20px 0px rgba(0, 0, 0, 0.5);
			display: none;
			@media (min-width: $lg) {
				display: inline-block;
				max-width: 400px;
				box-shadow: 7px 10px 20px 0px rgba(0, 0, 0, 0.5);
				top: 80px;
			}
		}
	}
	.sponsors{
		background-color: $light;
	}
	.footer {
		position: relative;
		z-index: 0;
	}
    main {
        .home-route & {
            background-image: url(./assets/images/bg-home.jpg);
            background-repeat: repeat-y;
            background-position: top center;
            background-size: cover; 
        }
    }
</style>
