import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store';
import * as storeLocal from 'store';

import {ModalService} from '../utils/ModalService';
import ga from '../utils/GoogleAnalytics';
import config from '../startup/config';
import {get} from 'lodash';

// using hash mode routing for the app fixes path issues with the background-image: url()
// used throughout the app
const myRouter = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from) {
		return {
			x: 0,
			y: 0
		}
	}
});

/**
 * @returns {Promise<never>|Promise<void>}
 */
function useCorrectDomain(to) {
	return () => {
		// redirect w/o the session ID in the URL parameter:
		if (config.usingLoggedInDomain && location.href.match(/\?sessionid/)) {
			let redirect = [
				location.protocol,
				'//',
				config.loggedInDomain
			];
			if (location.port) {
				redirect.push(`:${location.port}`)
			}
			redirect.push('/enter-code');
			redirect = redirect.join('');
			return Promise.reject({
				msg: '*** removing sessionid and finally redirecting to enter codes',
				redirect
			});
		}

		// note: this if() check might need to be moved to line 28
		if (config.domainCheckRequired) {

// console.log('useCorrectDomain: loggedIn=',store.get('auth/isLoggedIn'),' on ', (config.usingLoggedInDomain?'game':'guest'),'domain');
			// logged in user on guest domain or guest on logged-in domain
			// redirect to other domain:
			if (store.get('auth/isLoggedIn') !== config.usingLoggedInDomain) {
				console.log('domain mismatch detected');
				let redirect = [
					location.protocol,
					'//',
					config.usingLoggedInDomain ? config.guestDomain : config.loggedInDomain
				];
				let lastRedirectTime = parseInt(storeLocal.get('redirect_to_play'));

				if (location.port) {
					redirect.push(`:${location.port}`)
				}

				// we need to preserve the sessionid parameter in case 3rd party cookies are blocked
				// J4U passes us the session_id via URL, and we need to retain this across domain jumps,
				// so that ultimately we can pass the sessionid to the GET /app/user identity request
				let eToken = store.get('auth/eToken');

				let redirectToGamesDomainWithSessionId = store.get('auth/isLoggedIn')
					&& !config.usingLoggedInDomain
					&& eToken;

				// todo we should have an eToken at this point, but we don't? why not?
				if (redirectToGamesDomainWithSessionId) {
					redirect.push('/enter-code');
					redirect.push('?sessionid=' + eToken);
				} else {
					redirect.push(location.pathname);
				}
				redirect.push(location.hash);
				redirect = redirect.join('');

				// todo finish this so that in case of a redirect loop, it tells the user to enable 3rd party cookies
				if (!config.usingLoggedInDomain && lastRedirectTime > 0) {
					if ($.now() - lastRedirectTime < 10000) {
						// Stopping Redirect:
						// if a redirect to ripitandwinitcodes has occurred within 10 seconds ago,
						// do nothing. stop here. This may indicate a cyclical redirect is happening.
						// I don't know what to do. just log out.
						store.dispatch('auth/logoutUser').then(_ => {
							store.dispatch('auth/refreshUser').then();
						});
						store.set('router/ready2', true);
						return;
						// return Promise.reject({msg: `Please enable third-party cookies to use this website.`});
					}
				}

				if (!config.usingLoggedInDomain) {
					storeLocal.set('redirect_to_play', $.now());
				}
				let rejection = {
					msg: 'bad hostname for auth status',
					redirect
				}
				// if user is on wrong domain, redirect
				let serverRedirect = store.get('auth/sessionData@redirect');
				if (serverRedirect) {
					// we should maybe log out of this domain
					rejection.redirect = serverRedirect;
					rejection.logout = true;
				}
				return Promise.reject(rejection);
			}
		}
		store.set('router/ready2', true);
		return Promise.resolve();
	}
}

// This guard will ensure that we have all the data necessary for routing
myRouter.beforeEach((to, from, next) => {
	store
		.dispatch('router/makeReady')
		.then(useCorrectDomain(to))
		.then(next)
		.catch((err) => {
			if (err.msg) {
				console.error('navigation error: ', err.msg);
			}

			if (err.redirect) {
				if (err.logout) {
					store.dispatch('auth/logoutUser').then(_ => {
						location.href = err.redirect;
					});
				} else {
					location.href = err.redirect;
				}
			}
		});
});

// Set the title
myRouter.afterEach((to, from, next) => {
	let title = [to.meta && to.meta.documentTitle || ''];
	title.push(config.siteTitle);
	document.title = title.filter(v => v).join(' | ');
});

let lastRouteName = '';
myRouter.afterEach((to, from) => {
	if (to.name != lastRouteName) {
		$("html, body").scrollTop(0);

		let focusable = $('body, .route-focus');
		if (navigator.userAgent.match(/iPad|iPhone/i)) {	// all iPhone/iPad browsers, because VoiceOver sucks
			focusable = focusable.filter('a, button, input, select, textarea');
			setTimeout(function () {
				focusable.first().focus();
			}, 1000);
		} else {
			focusable.first().focus();
		}

		store.set('ada/alert', '');

		lastRouteName = to.name;
	}

	// Close any open modals
	let modals = ModalService.getVisibleModals();
	for (let i = 0; i < modals.length; i++) {
		if (modals[i]) {
			modals[i].destroy();
		}
	}

	// Alert the user if they're on the wrong website
	let sessionData = store.get('auth/sessionData');
	if (sessionData.redirect_reason) {
		let modal = {
			message: sessionData.redirect_reason,
			buttons: [
				{label: 'Continue'}
			],
			opts:    {
				showCloseX:     false,
				showHeader:     false,
				contentClasses: 'theme-box'
			}
		}
		ModalService.create('generic', modal).on('Continue close', () => {
			top.location.href = sessionData.redirect;
		})
	}
});

// analytics tracking
myRouter.afterEach((to, from) => {
	try {
		// google/FB page view events go here
	} catch (ex) {
		console.error(ex)
	}
});

export default myRouter;
