window.blockAdBlock = false;

// import 'bootstrap'
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';

import 'blockadblock'
import '../utils/postMessage'
// I don't think we need this if we're defining it with the webpack.ProvidePlugin
//window.$ = $;
