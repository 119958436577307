<template>
    <div class="select-cards">
        <div class="content-area container-fluid px-0 mt-4 bg-img-dark text-white">
            <div class="row mb-0 justify-content-center mx-0">
                <div class="col-md-9 col-xl-6 py-3 py-lg-5 mb-5 mt-4 mt-md-0">
                    <div v-if="action !== 'Choose 3'">
                        <h1 class="text-center font-size-largest font-weight-normal mt-lg-4 text-appear" :class="{'blink': textAppearTransition, 'show-this': showThisText}"  v-html="pageHeadline"></h1>
                        <p class="font-special font-size-xl mb-0 text-center color-accent">Your selected numbers</p>
                        <div class="row justify-content-center mb-5">
                            <div class="col-6 col-md-2 ball-cont" v-for="(chosenCard, index) in chosenCards" :key="chosenCard">
                                <div class="text-center matchball">
                                    <div class="ball-number selection">
                                        <span class="number">{{ chosenCard }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="font-size-xxl font-special text-center text-appear mb-0" :class="{ 'blink': textAppearTransition,'show-this': showThisText }" v-html="instruction"></p>
                        <p class="font-size-largest font-special mb-0 text-appear text-center"  :class="{ 'blink': textAppearTransition }" v-if="action === 'Non-Winner'">{{ reason }}</p>
                    </div>
                    <div v-else>
                        <h1 class="text-center font-size-largest page-title font-weight-normal mt-lg-4" :class="{'blink': textAppearTransition, 'show-this': showThisText}"  v-html="pageHeadline"></h1>
                        <h2 class="text-center font-size-billboard page-prize font-weight-normal"  :class="{'blink': textAppearTransition, 'show-this': showThisText}"  v-if="action === 'Choose 3'" v-html="prizeCategory"></h2>
                        <p class="font-size-largest color-yellow text-center font-special page-ins" :class="{ 'font-size-billboard' : reveal,'blink': textAppearTransition,'show-this': showThisText }">{{ instruction }}</p>
                    </div>
                    <template v-if="action === 'Choose 3'">
                        <div class="ball-selection px-1 px-lg-5 le-balls justify-content-center" :class="{'show-this': showThisText}"  @transitionend="showBallPickItems">
                            <div class="text-center ball-pick-item matchball" :class="{'show-this': visibleBalls >= index}"  v-for="index in 25" :key="index">
                                <input type="checkbox"
                                       :id="`ball-${index}`"
                                       name="pick[]"
                                       :value="index"
                                       class="d-none ball-pick "
                                       v-model="chosenCards"
                                >
                                <label :for="`ball-${index}`" class="ball-number small-ball"  :class="{selection: chosenCards.indexOf(index)>=0}">
                                    <span class="number">{{ index }}</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <submit-button class="btn rounded candy-gradient-vertical color-light py-1 px-5 ticket my-4"
                                           :disabled="chosenCards.length<3"
                                           :loading="busy"
                                           @click="submit3"
                                           v-if="action==='Choose 3' && !nextAction">Submit to begin ball draw
                            </submit-button>
                        </div>
                    </template>

                    <div class="prize-info" v-if="action==='Reveal Prize'">
                        <main class="content-area container-fluid">
                            <div class="row">
                                <div class="col">
                                    <p class="font-special text-center font-size-xl mt-5 mb-0">The winning numbers</p>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-6 col-md-2 ball-cont" v-for="(chosenCard, index) in winningCards" :key="chosenCard">
                                    <div class="text-center matchball">
                                        <div class="ball-number big-ball" :class="{ 'ball-in' : winningBallTransition }" @transitionend="showNextSteps(index)">
                                            <span class="number">{{ chosenCard }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <figure class="ball-machine"  :class="{ 'machine-in' : machineTransition }" @transitionend = "showBalls">
                            <div class="balls matchball">
                                <div class="ball-number tiny-ball" v-for="(ball, bc) in ballsInMachine" :key="bc">
                                    <span class="number">{{ ball }}</span>
                                </div>
                            </div>
                            <img class="machine-image" :src="require(`../assets/images/machine.png`)" alt="Machine">
                        </figure>
                        <div class="fake-modal next-steps text-center" :class="{ 'steps-in' : stepsTransition }" @transitionend="stepsEnd">
                            <p class="font-size-largest text-center font-special color-accent">{{ modalText }}</p>
                            <router-link class="btn ticket my-2 my-lg-4 mx-2 rounded candy-gradient-vertical color-light text-decoration-none" :to="{name:'enter-code'}" @click="modalShow = false">Enter Another Code</router-link>
                            <router-link class="btn ticket my-2 my-lg-4 mx-2 rounded candy-gradient-vertical color-light text-decoration-none" :to="{name:'code-history'}">View Code History</router-link>
                        </div>
                    </div>
                    <div class="prize-info" v-if="action === 'Non-Winner'">
                        <main class="content-area container-fluid">
                            <div class="row">
                                <div class="col">
                                    <p class="font-special text-center font-size-xl mt-5 mb-0">The winning numbers</p>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-6 col-md-2 ball-cont" v-for="(chosenCard, index) in winningCards" :key="chosenCard">
                                    <div class="text-center matchball">
                                        <div class="ball-number big-ball" :class="{ 'ball-in' : winningBallTransition }" @transitionend="showNextSteps(index)">
                                            <span class="number">{{ chosenCard }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <figure class="ball-machine"  :class="{ 'machine-in' : machineTransition }" @transitionend = "showBalls">
                            <div class="balls matchball">
                                <div class="ball-number tiny-ball" v-for="(ball, bc) in ballsInMachine" :key="bc">
                                    <span class="number">{{ ball }}</span>
                                </div>
                            </div>
                            <img class="machine-image" :src="require(`../assets/images/machine.png`)" alt="Machine">
                        </figure>
                        <div class="fake-modal next-steps text-center" :class="{ 'steps-in' : stepsTransition }" @transitionend="stepsEnd">
                            <!--
                            <p class="font-size-largest text-center font-special">{{ modalText }}</p>
                            -->
                            <router-link class="btn ticket my-2 my-lg-4 mx-2 rounded candy-gradient-vertical color-light text-decoration-none" :to="{name:'enter-code'}" @click="modalShow = false">Enter Another Code</router-link>
                            <router-link class="btn ticket my-2 my-lg-4 mx-2 rounded candy-gradient-vertical color-light text-decoration-none" :to="{name:'code-history'}">View Code History</router-link>
                        </div>
                    </div>
                    <b-modal id="next-steps"
                             no-close-on-backdrop
                             content-class=""
                             v-model="modalShow"
                             centered
                             size="lg"
                             tabindex="-1"
                             role="dialog"
                             hide-footer
                             hide-header>
                        <div class="text-center">
                            <p class="font-size-banner font-special mb-0">{{ modalDesignation }}</p>
                            <h2 class="font-size-largest font-special mb-5">{{ modalCongratsText }}</h2>
                            <h3 class="font-body font-size-default text-uppercase font-weight-normal" v-if="modalInstructions">{{ modalInstructions }}</h3>
                            <p>{{ modalText }}</p>
                            <router-link class="btn ticsket my-4 btn-block rounded candy-gradient-vertical color-light text-decoration-none" :to="{name:'enter-code'}" @click="modalShow = false">Enter Another Code</router-link>
                            <router-link class="btn ticsket my-4 btn-block rounded candy-gradient-vertical color-light text-decoration-none" :to="{name:'code-history'}">View Code History</router-link>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

.text-appear{
    opacity: 1;
    transition: opacity 0.5s ease-out;
    &.blink{
        opacity: 0;
    }
}
.le-balls,
.page-title,
.page-prize,
.page-ins,
.ball-pick-item
{
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    &.show-this{
        opacity: 1;
    }
}

.ball-pick-item {
    transition-delay: 0s;
    transition-duration: .02s;
}

.page-prize{
    transition-delay: 1s;
}
.page-ins{
    transition-delay: 1.6s;
}
.le-balls{
    transition-delay: 2s;
}
.prize-info{
    position: relative;
    main.content-area{
		padding-top: 0;
	}
}
.next-steps{
    /* position: absolute; */
    /* top: 110%; */
    /* left: 0; */
    /* right: 0; */
    opacity: 0;
    margin: 60px auto 0;
    transform: translate(0,50%);
    transition: all 0.5s ease-out;
    &.steps-in{
        opacity: 1;
        transform: translate(0,0);
    }
}
.ball-cont{
    .big-ball{
        opacity: 0;
        transform: translate(0, 50%);
        transition: all 0.5s ease-out;
    }
    &:nth-child(1){
        .big-ball{
            &.ball-in{
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
    &:nth-child(2){
        .big-ball{
            transition-delay: 1s;
            &.ball-in{
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
    &:nth-child(3){
        .big-ball{
            transition-delay: 2s;
            &.ball-in{
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
}
.ball-pick-confimation,
.ball-selection {
    display: grid;
    grid-template-columns: repeat(5, 14%);
    grid-row-gap: 0;
    // @media (min-width: $lg){
    //     grid-row-gap: 2rem;
    // }

}

.ball-pick-confimation {
    grid-template-columns: repeat(3, 33.33%);
}

.ball-selected {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 404px;
    position: absolute;
    width: 43.9%;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    @media (min-width: $collapse) {
        width: 100%;
    }
}

.ball-number {
    text-align: center;
    cursor: pointer;

    .number {
        display: inline-block;
        height: 60px;
        width: 50px;
        padding-top: 15px;
        background-size: contain;
        background-position: center center;
        font-family: $fontSpecial;
        @include rfs(35px);
        @media (min-width: $md){
            height: 80px;
            width: 80px;
            padding-top: 10px;
            font-size: 45px
        }
        &:hover,
        &:focus {
            background-size: auto 100%;
            background-position: center center;
        }
    }

    &.big-ball {
        padding: 0;
        cursor: auto;

        .number {
            font-size: 52px;
            height: 90px;
            width: 90px;
            background-size: auto 100%;
            background-position: center center;
            @media (min-width: $md){
                font-size: 47px;
                height: 90px;
                width: 90px;
            }
            
            @media (min-width: $lg){
                height: 90px;
                width: 90px;
                font-size: 50px;
            }
            @media (min-width: $xxl){
                height: 90px;
                width: 90px;
                padding-top: 6px;
                font-size: 55px;
            }
        }

    }

    &.tiny-ball {
        .number {
            font-size: 14px;
            height: 40px;
            width: 40px;
            @media (min-width: $md){
                height: 80px;
                width: 80px;
                padding-top: 15px;
                font-size: 35px
            }
            @media (min-width: $collapse) {
                @include rfs(35px);
            }
        }
    }
}
.ball-pick-item .number.checked {
    background-size: auto 100%;
    background-position: center center;
}

.ball-machine {
    position: absolute;
    top: 110%;
    width: 100%;
    margin-top: 100px;
    text-align: center;
    transition: all 0.5s ease-out;
    opacity: 0;
    transform: translate(0, 50%);
    &.machine-in{
        opacity: 1;
        transform: translate(0, 0);
    }
    img {
        max-width: 100%;
    }
}
.balls{
    position: absolute;
    height: 50%;
    width: 50%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
}
.tiny-ball{
    position: absolute;
    bottom: 0;
    animation: bounceSm 1s linear infinite;
    left: 0;
    
    &:nth-child(1){
        left: 75%;
        animation: bounceSm 1s linear infinite;
    }
    &:nth-child(2){
        left: 0;
        animation: bounceMd 1s linear infinite;
    }
    &:nth-child(3){
        left: 12%;
        animation: bounceMd 1s linear infinite;
    }
    &:nth-child(4){
        left: 35%;
        animation: bounceSm 1s linear infinite;
    }
    &:nth-child(5){
        left: 55%;
        animation: bounceLg 1s linear infinite;
    }
    &:nth-child(6){
        left: 65%;
        animation: bounceMd 1s linear infinite;
    }
    &:nth-child(7){
        left: 32%;
        animation: bounceLg 1s linear infinite;
    }
    &:nth-child(8){
        left: 60%;
        animation: bounceLg 1s linear infinite;
    }
    &:nth-child(9){
        left: 25%;
        animation: bounceSm 1s linear infinite;
    }
    &:nth-child(10){
        left: 40%;
        animation: bounceLg 1s linear infinite;
    }

}
@keyframes bounceMd {
  0% {
        opacity: 0;
        transform: translate(0, 0) rotate(0deg);
    }
    50% {
        opacity: 1;
        transform: translate(75%, -75%) rotate(180deg);
    }
    100%{
        opacity: 0;
        transform: translate(100%, 0) rotate(360deg);
    }
}
@keyframes bounceSm{
    0% {
        opacity: 0;
        transform: translate(0, 0) rotate(0deg);
    }
    50% {
        opacity: 1;
        transform: translate(-25%, -155%) rotate(180deg);
    }
    100%{
        opacity: 0;
        transform: translate(-100%, 0) rotate(360deg);
    }
}
@keyframes bounceLg{
    0% {
        opacity: 0;
        transform: translate(0, 0) rotate(0deg);
    }
    50% {
        opacity: 1;
        transform: translate(0, -200%) rotate(180deg);
    }
    100%{
        opacity: 0;
        transform: translate(0, 0) rotate(360deg);
    }
}


.matchball {
    // selection page
    .ball-number,
    [type="checkbox"]+.ball-number {
        background: transparent url('../assets/images/unselected.png') no-repeat center;
        background-size: contain;
        color: $dark;
        /*&.big-ball{
            color: $light;
            text-shadow: 0 2px $dark;
            background: transparent url('../assets/images/selected.png') no-repeat center;
            background-size: contain;
        }*/
    }
    [type="checkbox"]:focus+.ball-number,
    [type="checkbox"]:hover+.ball-number,
    .ball-number.selection {
        background: transparent url('../assets/images/selected.png') no-repeat center;
        background-size: contain;
        color: $light;
        text-shadow: 0 2px $dark;
    }

    .btn-link {
        color: $dark;
    }

    .random-ball {
        background: transparent url('../assets/images/unselected.png') no-repeat center;
        background-size: contain;
        color: $dark;
    }
}



</style>
<script>
import {get} from 'lodash';
import vuexStore from '../store'

export default {
    computed: {

        pageHeadline() {
            if (this.playingForPrizeName) {
                // return this.title + ' ' + this.prizeNameWithDollarSign;
                return this.title;
            }
            return null
        },
        playingForPrizeName() {
            return get(this.$store.get('auth/match3Game'), 'prize_name');
        },

        isDev() {
            return this.$config.env === 'local'
        },

        prizeNameWithDollarSign() {
            if (this.prizeCategory.substr(0, 1) === '$') {
                return '<sup class="currency">$</sup>' + this.prizeCategory.substr(1);
            }
            return this.prizeCategory;
        }

    },
    data() {
        return {
            busy:                   false,
            instruction:            'Pick 3 numbers below',
            title:                  'You are playing for',
            chosenCards:            [],
            winningCards:           [],
            revealCards:            false,
            cards:                  {},
            action:                 'Choose 3',
            prizeCategory:          '', // I am not quite sure but there might be multiple categories or level that the user is playing, might be playing for 1,000, 10,000, or 1,000,000.
            prize:                  null,
            nextAction:             null,
            chosenConsolationPrize: null,
            modalShow:              false,
            modalDesignation:       '',
            modalCongratsText:      '',
            modalInstructions:      '',
            modalText:              '',
            hTimer:                 null,
            reveal:                 false,
            reason:                 '',
            // For transitions
            showThisText:           false,
            winningBallTransition : false,
            machineTransition:      false,
            machineHideTotally:     false,
            stepsTransition:        false,
            textAppearTransition:   false,

            // Machine Balls
            ballsInMachine:         [1,2,3,4,5,6,7,8,9,15,17,20,21,25],

            nextBallToShow:0,
            visibleBalls: 0
        }
    },
    /**
     * Prevent entry to game board unless a match-3 game has been started
     * @param to
     * @param from
     * @param next
     */
    beforeRouteEnter(to, from, next) {
        let playing = vuexStore.get("auth/user@playMatch3Game");
        if (!playing) {
            next({name: 'enter-code'});
            return;
        }

        next();
    },
    created() {
        let game = this.$store.get("auth/user@playMatch3Game");
        if (game) {
            this.action = game.action;
            this.prizeCategory = game.prize_name;
        }

    },

    methods: {

        // submit chosen 3 cards
        submit3() {
            this.busy = true;
            this.showThisText = false;
            this.$http.post(`/game/submitMatch3`, {chosenCards: this.chosenCards}).then(response => {
                let data = response.data;
                if (data) {
                    this.chosenCards = this.chosenCards.sort(function(a, b){return a-b});
                    // Hide the text first
                    this.textAppearTransition = true;
                    this.action = data.action;
                    this.reveal = true;
                    this.prize = data.prize;
                    this.showThisText = true;
                    let delay = 3000;
                    // let delay = 20000;
                    // setTimeout(() => {
                    //    this.winningBallTransition = true;     
                    // }, 1000);
                    setTimeout(() => {
                        this.scrollDown();
                        // Show the machine
                        this.machineTransition = true;
                        // Handles the text in the page header
                        this.title = data.title;
                        if (data.action === 'Non-Winner') {
                            this.instruction = data.instructions;
                            this.reason = data.reason;
                            this.winningCards = data.winning_cards.sort(function(a, b){return a-b});
                            // Not a match at all
                            if (!data.congratsText && !data.contentText) {
                               this.instruction = 'Not a match'; 
                               this.reason = 'Sorry. Thanks for Playing!'; 
                            }
                            // Likely partial matching with sweepstakes entry will not have instructions
                            if (!this.instruction) {
                                this.instruction = 'Thank you for playing<br><span class="font-size-xl color-yellow">You are entered in this week\'s sweepstakes</span>';
                            }
                        } else {
                            // This has to be pulled
                            this.prize = data.name
                            this.instruction = 'Congratulations You Won ' + '<br><span class="font-size-billboard color-yellow">' + data.prize.title + '</span>';
                            this.winningCards = this.chosenCards;
                        }
                    }, 1000);

                    // const machine = document.querySelector( '.ball-machine' );
                    // console.log( machine );
                    // machine.ontransitionend = () => {
                    //     this.winningBallTransition = true;
                    // };
                    //this.setModalText(data);
                    setTimeout(() => {
                        // this.setModalText(data);
                        // this.modalShow = true;
                        this.setModalText(data);
                    }, delay);
                    return;
                } else {
                    // error in response
                }
            }).catch(error => {
                console.error(error);
                // who knows what to do here
                this.$modalService.create("generic",
                    {
                        title:      'Sorry...',
                        message:    'We are unable to process your request at this time.',
                        isWinner:   false,
                        opts:{showCloseX: false}
                    })
                    .on('close', () => {
                        top.location.reload();
                        // this.$router.push({name: 'enter-code'})
                    })
            }).finally(() => {
                this.busy = false;
            })

        },
        setModalText(responseFromServer) {
            this.modalDesignation = responseFromServer.designation;
            this.modalCongratsText = responseFromServer.congratsText;
            this.modalInstructions = responseFromServer.instructions;
            this.modalText = responseFromServer.contentText;
        },
        stepsEnd(){
            this.machineHideTotally = true;
        },
        showNextSteps( count ){
            if( count === 2 ){
                this.textAppearTransition = false;
                this.machineTransition = false;
                this.stepsTransition = true;
                // this.modalShow = true;
                setTimeout(() => {
                    // remove the ball machine to avoid gap at the bottom of the page
                    $('.ball-machine').remove();
                    this.scrollTop();
                }, 800);
            }
        },
        showBalls(){
            if( this.machineTransition == true ){
                this.winningBallTransition = true;
                // this.textAppearTransition = false;
            }
        },
        showThese(){
            setTimeout(() => {
                this.showThisText = true;
            }, 200);
        },
        /**
         * transitionend event propogates to parents
         * @param x
         */
        showBallPickItems(x) {
           this.visibleBalls++;
        },
        scrollTop() {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        },
        scrollDown() {
            window.scrollTo(0, document.body.scrollHeight/2 || document.documentElement.scrollHeight/2);
        },
    },
    mounted() {
        if (this.isDev) {
            window.advance = () => {
                this.action = this.nextAction;
                this.nextAction = '';
            }
        }
        this.showThese(); 
    },
    watch: {
        chosenCards(to, from) {
            if (to.length > 3) {
                this.chosenCards = from.slice(0, 3);
            }
        }
    },
}
</script>
