<template>
	<div class="container football">

		<div class="theme-box my-100 py-4 px-3 px-xl-5 text-center d-flex align-items-center justify-content-center flex-column">
			<h1>You are playing for $10,000</h1>
			<p class="h4 color-primary font-weight-normal">You selected numbers: 5, 11, and 14</p>
		</div>

		<div class="theme-box my-100 py-4 px-3 px-xl-5 text-center d-flex align-items-center justify-content-center flex-column">
			<p class="h4 color-primary font-weight-normal">Congratulations!</p>
			<h1>You won $10,000</h1>
			<p class="h4 color-primary font-weight-normal">You selected numbers: 5, 11, and 14</p>

			<div class="d-flex justify-content-center align-items-center flex-wrap">
				<div v-for="(i, key) in [14, 5, 11]" :key="key" class="selection d-flex align-items-center justify-content-center" :class="'selection-' + key">
					{{i}}
				</div>
			</div>
		</div>

		<div class="theme-box my-100 py-4 px-3 px-xl-5 text-center d-flex align-items-center justify-content-center flex-column">
			<h1>Not a Match</h1>
			<p class="h4 font-weight-normal">Sorry. Thanks for playing!</p>
			<p class="h4 color-primary font-weight-normal">You selected numbers: 5, 11, and 14</p>

			<div class="d-flex justify-content-center align-items-center flex-wrap">
				<div v-for="(i, key) in [14, 5, 11]" :key="key" class="selection d-flex align-items-center justify-content-center" :class="'selection-' + key">
					{{i}}
				</div>
			</div>
		</div>

		<div class="machine text-center mt-100 d-none d-xl-block">
			<img :src="require(`../assets/images/machine.png`)" alt="" role="presentation" class="img-fluid">
			<div v-for="(i, key) in [14, 5, 11]" :key="key" class="selection d-flex align-items-center justify-content-center" :class="'selection-' + key">
				{{i}}
			</div>
			<div v-for="(i, key) in [1,7,19,24,6,13,2]" :key="key" class="random-ball d-flex align-items-center justify-content-center" :class="'ball-' + key">
				{{i}}
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: "football-2",
		data() {
			return {};
		},
	};
</script>

<style lang="scss" scoped>
	.theme-box {
		min-height: 500px;
	}

	.selection {
		width: 118px;
		height: 108px;
		font-size: 35px;
		font-family: $fontSpecial;
		@media (min-width: $xl) {
			width: 221px;
			height: 202px;
			font-size: 87px;
		}
	}

	.machine {
		position: relative;
		.selection {
			position: absolute;
			width: 118px;
			height: 108px;
			font-size: 35px;
			top: 20px;
		}
		.selection-0 {
			left: 29%;
		}
		.selection-1 {
			left: 44.6%;
		}
		.selection-2 {
			left: 60%;
		}
		.random-ball {
			position: absolute;
			width: 118px;
			height: 108px;
			font-size: 35px;
			font-family: $fontSpecial;
			&.ball-0 {
				top: 210px;
				left: 260px;
				transform: rotate(93deg);
			}
			&.ball-1 {
				top: 252px;
				left: 320px;
				transform: rotate(196deg);
			}
			&.ball-2 {
				top: 180px;
				left: 400px;
				transform: rotate(266deg);
			}
			&.ball-3 {
				top: 110px;
				left: 509px;
				transform: rotate(98deg);
			}
			&.ball-4 {
				top: 230px;
				left: 599px;
				transform: rotate(-12deg);
			}
			&.ball-5 {
				top: 200px;
				left: 689px;
				transform: rotate(88deg);
			}
			&.ball-6 {
				top: 250px;
				left: 750px;
				transform: rotate(30deg);
			}
		}
	}
</style>
