import {make} from 'vuex-pathify';

export default {
	namespaced: true,
	state: {
		siteKey: '6Ldr0WIpAAAAABzbgJ1znCJ4hzOwpKPUMHXAaalJ',
		status: 'unloaded'
	},
	mutations: {
		...make.mutations(['status'])
	},
	getters: {
		...make.getters(['siteKey', 'status'])
	}
}
